import React from 'react';

import Layout from 'components/Layout';
import SEO from 'components/SEO';
import Updates from 'components/Updates';

const UpdatePage = () => {
  return (
    <Layout>
      <SEO title="Updates" />
      <Updates />
    </Layout>
  );
};

export default UpdatePage;
