import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import SEO from 'components/SEO';
import Container from 'components/ui/Container';
import TitleSection from 'components/ui/TitleSection';
import FormatHtml from 'components/utils/FormatHtml';


const Updates = () => {
  const { mdx } = useStaticQuery(graphql`
    query {
      mdx(frontmatter: { category: { eq: "updates" } }) {
        body
        frontmatter {
          title
          subtitle
          description
        }
      }
    }
  `);

  const sectionTitle = mdx.frontmatter;

  return (
    <Container>
      <SEO title={sectionTitle.title} description={sectionTitle.description} />
      <TitleSection title={sectionTitle.title} subtitle={sectionTitle.subtitle} center />
      <FormatHtml content={mdx.body} />
    </Container>
  );
};

export default Updates;
